<template>
  <div class="timer-container">
    <div
      class="semicircle"
      :class="{ left: lessThanFive, right: !lessThanFive }"
      :style="leftStyle"
    ></div>
    <div class="semicircle right" :style="rightStyle"></div>
    <div class="text" :class="{ msg: text === 0 }">
      {{ text === 0 ? '未作答' : text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountdownTimer',
  props: {
    rotateDeg: {
      type: Number,
      default: 0
    },
    text: {
      type: Number,
      default: 0
    }
  },
  computed: {
    lessThanFive() {
      return this.rotateDeg < 180
    },
    rightStyle() {
      return {
        transform: `rotate(${this.rotateDeg}deg)`
      }
    },
    leftStyle() {
      return {
        backgroundColor: this.lessThanFive ? '#fff' : '#a77a33',
        zIndex: 1
      }
    }
  }
}
</script>

<style lang="less" scoped>
.timer-container {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #a77a33;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .semicircle {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
  }
  .left {
    left: 0;
    border-top-left-radius: 100% 50%;
    border-bottom-left-radius: 100% 50%;
    background-color: #fff;
    transform-origin: 100% 50%;
  }
  .right {
    right: 0;
    border-top-right-radius: 100% 50%;
    border-bottom-right-radius: 100% 50%;
    background-color: #fff;
    transform-origin: 0 50%;
  }
  .text {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-weight: 700;
    color: #032e9d;
    background-color: #fff;
    z-index: 2;
  }
  .msg {
    font-size: 10px;
  }
}
</style>
