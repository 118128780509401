<template>
  <div class="main">
    <page-title />
    <div class="main-box">
      <svg
        v-if="isAnswered && isRight"
        class="animation clock"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.2 130.2"
      >
        <circle
          class="path circle"
          fill="none"
          stroke="#73AF55"
          stroke-width="6"
          stroke-miterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <polyline
          class="path check"
          fill="none"
          stroke="#73AF55"
          stroke-width="10"
          stroke-linecap="round"
          stroke-miterlimit="10"
          points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
      </svg>
      <svg
        v-else-if="isAnswered"
        class="animation clock"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.2 130.2"
      >
        <circle
          class="path circle"
          fill="none"
          stroke="#D06079"
          stroke-width="6"
          stroke-miterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <line
          class="path line"
          fill="none"
          stroke="#D06079"
          stroke-width="10"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="34.4"
          y1="37.9"
          x2="95.8"
          y2="92.3"
        />
        <line
          class="path line"
          fill="none"
          stroke="#D06079"
          stroke-width="10"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="95.8"
          y1="38"
          x2="34.4"
          y2="92.2"
        />
      </svg>
      <count-down-timer
        v-else
        class="clock"
        :rotate-deg="((totalTime - restTime) / totalTime) * 360"
        :text="restTime"
      />
      <transition name="slide">
        <div v-if="show" class="question" v-html="question.title"></div>
      </transition>
      <ul class="options">
        <template v-if="question.questionTypeId === 'single_select'">
          <answer-option
            v-for="(item, index) in question.questionOptions.filter(
              item => item.value
            )"
            :key="question.id + '_' + item.key"
            :options-info="optionsInfo[index]"
            @click.native.once="judgeResult(index, item.key)"
          >
            <span v-html="item.value"></span>
          </answer-option>
        </template>
        <template v-else-if="question.questionTypeId === 'decision'">
          <answer-option
            :key="question.id + '_' + 'T'"
            :options-info="optionsInfo[0]"
            @click.native.once="judgeResult(0, 'T')"
          >
            <van-icon key="T" name="success" />
          </answer-option>
          <answer-option
            :key="question.id + '_' + 'F'"
            :options-info="optionsInfo[1]"
            @click.native.once="judgeResult(1, 'F')"
          >
            <van-icon key="F" name="cross" />
          </answer-option>
        </template>
      </ul>
      <transition name="slide">
        <div v-if="show" class="question-info">
          <span class="question-info-header">知识点：</span>
          <span v-html="question.examingPoint"></span>
        </div>
      </transition>
      <status-bar
        class="bottom-bar"
        :count="questionCount"
        :current="currentQuestion"
      />
      <div style="height: 62px"></div>
      <!-- <result-page
        v-if="isEnd"
        :result="isRight"
        :title="showMessage.title"
        :text="showMessage.text"
        :score="resultData.score"
      /> -->
    </div>
    <audio ref="timeout" :src="require('@/assets/audio/timeout.wav')">
      浏览器版本过低，请尽快升级
    </audio>
    <audio ref="wrong" :src="require('@/assets/audio/wrong.mp3')">
      浏览器版本过低，请尽快升级
    </audio>
  </div>
</template>
<script>
import { Toast } from 'vant'
import CountDownTimer from '@/components/CountDownTimer'
import AnswerOption from '@/components/AnswerOption'
import StatusBar from '@/components/StatusBar'
import storage from 'store'

const times = 120

export default {
  components: {
    CountDownTimer,
    AnswerOption,
    StatusBar
  },
  data() {
    return {
      resultData: {},
      show: false,
      question: {
        questionTitle: '',
        questionOptions: [],
        questionId: ''
      },
      // 总题数
      questionCount: 10,
      // 当前第几题
      currentQuestion: 0,
      // 总共答题时间
      totalTime: 30,
      // 剩余答题时间
      restTime: 30,
      isAnswered: false,
      // 本题是否答对
      isRight: false,
      // 是否结束（全部答对或答错一题）
      isEnd: false,
      optionsNum: 4,
      optionsInfo: [],
      // 花费的时间，第一位开始时间，第二位结束时间
      costTime: [],
      // 总分
      totalScore: 0
    }
  },
  computed: {
    questionList() {
      return this.$store.state.questions
    }
  },
  watch: {
    optionsInfo() {
      this.isAnswered = this.optionsInfo.some(element => element.selected)
    }
  },
  created() {
    this.resetData()
    // this.getQuestion()
  },
  mounted() {
    storage.remove('ANSWER_RESULT')
    if (this.$store.state.gameStart) {
      this.showQuestion()
    } else {
      this.$router.replace('/home')
    }
    // this.showQuestion()
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval)
  },
  methods: {
    resetData() {
      this.show = false
      this.optionsInfo = new Array(this.optionsNum).fill({ selected: false })
      this.isRight = false
      this.restTime = times
      this.totalTime = times
    },
    countdown() {
      clearInterval(this.countdownInterval)
      this.countdownInterval = setInterval(() => {
        if (this.restTime === 1) {
          this.playAudio('timeout')
        }
        if (this.restTime === 0) {
          this.optionsInfo = new Array(this.optionsNum).fill({ selected: true })
          clearInterval(this.countdownInterval)
          this.showResult()
          return
        }
        this.restTime -= 1
      }, 1000)
    },
    showQuestion() {
      if (this.questionList && this.questionList.list) {
        this.question = this.questionList.list[this.currentQuestion++]
        this.show = true
        this.questionCount = this.questionList.list.length
        this.countdown()
        // 开始计时
        this.costTime[0] = new Date().getTime()
        if (this.question.questionTypeId == 'decision') {
          this.optionsInfo = new Array(2).fill({ selected: false })
        } else {
          this.optionsInfo = new Array(this.question.choiceList.length).fill({
            selected: false
          })
        }
      }
    },
    // 判断选择是否正确
    judgeResult(index, key) {
      console.log('--answer=>', index, key)
      if (this.isAnswered) return
      this.costTime[1] = new Date().getTime()
      this.$http({
        url: '/xt-exam/etQuestion/score',
        method: 'post',
        data: {
          userExamId: this.questionList.id,
          answer: key,
          questionId: this.question.id
        }
      }).then(response => {
        // eslint-disable-next-line no-console
        console.log('judgeResult:', response.data)
        this.resultData = response.data
        this.$store.commit('SET_RESULT', response.data)
        if (response.data.status == 1) {
          // 是否作答和是否答对同步更新
          this.isRight = true
          this.$set(this.optionsInfo, index, {
            selected: true,
            state: true
          })
          // this.totalScore += data.score;
          // 答对停止倒计时
          clearInterval(this.countdownInterval)
          // 判断结束或者进行下一题
          if (this.currentQuestion === this.questionCount) {
            // 播放动画后展示结果
            this.showResult()
          } else {
            // 播放动画后继续下一题
            setTimeout(() => {
              this.resetData()
            }, 1000)
            setTimeout(() => {
              this.showQuestion()
            }, 1800)
          }
        } else {
          // 答错则停止倒计时
          // clearInterval(this.countdownInterval)
          // this.$set(this.optionsInfo, index, {
          //   selected: true,
          //   state: false
          // })
          // this.playAudio('wrong')
          // this.showResult()
          // 答错后播放动画后继续下一题
          this.$set(this.optionsInfo, index, {
            selected: true,
            state: false
          })
          if (this.question.questionTypeId == 'decision') {
            if (response.data.trueAnswer === 'T') {
              this.$set(this.optionsInfo, 0, {
                selected: true,
                state: true
              })
            } else {
              this.$set(this.optionsInfo, 1, {
                selected: true,
                state: true,
                isCorrect: true
              })
            }
          } else {
            this.$set(
              this.optionsInfo,
              this.question.questionOptions.findIndex(
                choice => choice.key === response.data.trueAnswer
              ),
              {
                selected: true,
                state: true,
                isCorrect: true
              }
            )
          }

          this.playAudio('wrong')
          if (this.currentQuestion === this.questionCount) {
            // 播放动画后展示结果
            this.showResult()
          } else {
            setTimeout(() => {
              this.resetData()
            }, 1000)
            setTimeout(() => {
              this.showQuestion()
            }, 1800)
          }
        }
      })
    },
    showResult() {
      // 结束游戏
      // this.endGame()
      // 播放动画后展示结果
      setTimeout(() => {
        // 随机获取提示文本
        // this.getRandomMessage(
        //   this.isRight ? this.successMessage : this.failMessage
        // )
        // this.isEnd = true
        storage.set('ANSWER_RELOAD', true)
        this.$router.push('/result')
      }, 1200)
    },
    playAudio(type) {
      if (this.mute) return
      switch (type) {
        case 'wrong':
          this.$refs.wrong.play()
          break
        case 'timeout':
          this.$refs.timeout.play()
          break
        default:
      }
    },
    getQuestion(params) {
      return this.$http({
        url: '/xt-exam/etQuestion/question',
        params
      }).then(response => {
        if (response.code == 200) {
          storage.set('ANSWER_QUESTIONID', response.data.id)
          try {
            const questionList = response.data
            questionList.list = questionList.list
              .map(question => ({
                ...question,
                questionId: question.id,
                ...JSON.parse(question.content)
              }))
              .map(question => ({
                ...question,
                questionTitle: question.title,
                questionOptions: question.choiceList
                  .filter(choice => choice[Object.keys(choice)[0]])
                  .map(choice => ({
                    key: Object.keys(choice)[0],
                    value: choice[Object.keys(choice)[0]]
                  }))
              }))
            this.$store.commit('SET_QUESTIONS', questionList)
            this.showQuestion()
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
          }
        } else {
          Toast.fail({
            message: response.data,
            onClose: () => {
              this.$router.push('/')
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  // .title-container {
  //   padding: 0 6.4vw;
  //   .title {
  //     margin-top: 8vh;
  //     width: 100%;
  //   }
  // }

  .main-box {
    background: #ffffff;
    position: relative;
    flex: 1;
    width: 100vw;
  }
  .clock {
    position: absolute;
    width: 4.18rem;
    height: 4.18rem;
    top: -2.09rem;
    left: 50%;
    margin-left: -2.09rem;
    box-sizing: border-box;
    border: 6px solid #fff;
  }
  .animation {
    display: block;
    border-radius: 50%;
    background-color: #fff;
    z-index: 1;
  }
  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
  }
  .circle {
    animation: dash 0.9s ease-in-out;
  }
  .line {
    stroke-dashoffset: 1000;
    animation: dash 0.9s 0.35s ease-in-out forwards;
  }
  .check {
    stroke-dashoffset: -100;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
  // 题目
  .question {
    margin: 30px 10% 10px;
    text-align: left;
    color: #444;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }

  .question-info-header {
    font-weight: 600;
    color: #f75151;
  }

  .question-info {
    margin: 0 10% 10px;
    text-align: left;
    color: #999;
    font-size: 14px;
    line-height: 16px;
  }
  // 选项
  .options {
    width: 80%;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    // 选项进入效果
    .slide-enter-active {
      &:nth-child(2) {
        transition-delay: 0.1s;
      }
      &:nth-child(3) {
        transition-delay: 0.2s;
      }
      &:nth-child(4) {
        transition-delay: 0.3s;
      }
      &:nth-child(5) {
        transition-delay: 0.4s;
      }
    }
    .slide-leave-active {
      &:nth-child(2) {
        transition-delay: 0.1s;
      }
      &:nth-child(3) {
        transition-delay: 0.2s;
      }
      &:nth-child(4) {
        transition-delay: 0.3s;
      }
      &:nth-child(5) {
        transition-delay: 0.4s;
      }
    }
  }
  // 题目进入动画
  .slide-enter-active {
    transition: all 0.8s;
  }
  .slide-leave-active {
    transition: all 0.6s;
  }
  .slide-enter {
    transform: translateX(100vw);
  }
  .slide-leave-to {
    transform: translateX(-100vw);
  }
  .bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 55px;
    background: #ffffff;
  }
}
</style>
