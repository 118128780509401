<template>
  <ul class="status-bar">
    <li
      v-for="n in count"
      :key="n"
      class="number"
      :class="{ active: n === current, next: n === current + 1 }"
    >
      {{ n }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'StatusBar',
  props: {
    count: {
      type: Number,
      required: true
    },
    current: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.status-bar {
  width: 90%;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 2.08rem;
  color: #c9c9c9;
  .number {
    display: flex;
    align-items: center;
    transition: all 0.5s;
  }
  .active {
    font-size: 3rem;
    font-weight: 600;
    color: #a77a33;
  }
  .next {
    color: #555;
  }
}
</style>
