<template>
  <transition appear name="slide">
    <li
      class="question-option"
      :class="{ [optionsInfo.state ? 'right' : 'wrong']: optionsInfo.selected }"
      :style="wrongClass"
    >
      <slot></slot>
      <!-- <span class="rate">{{ optionsInfo.selected ? rate : '' }}</span> -->
    </li>
  </transition>
</template>

<script>
export default {
  name: 'Option',
  props: {
    optionsInfo: {
      type: Object,
      default: () => {}
    },
    rate: {
      default: '100%',
      type: String
    }
  },
  computed: {
    wrongClass() {
      return {
        backgroundSize:
          this.optionsInfo.selected && !this.optionsInfo.state
            ? `${this.rate} 100%`
            : ''
      }
    }
  }
}
</script>

<style lang="less">
.question-option {
  margin: 18px 0;
  padding: 0;
  border-radius: 24px;
  //border: 1px solid #dadada;
  text-align: left;
  padding: 8px 23px;
  line-height: 32px;
  vertical-align: middle;
  color: #444;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.8s;
  background-size: 0 100%;
  box-shadow: 0 0 4px 4px rgba(200, 200, 200, 0.1);
  word-wrap: break-word;
  .rate {
    font-size: 14px;
    font-weight: 500;
    color: #c9c9c9;
    margin-right: 12px;
    float: right;
  }
  p {
    margin: 0;
  }
}
.question-option.wrong {
  background: linear-gradient(to left, #f84c7f, #fec1d3) no-repeat;
}
.question-option.right {
  background: linear-gradient(to left, #439421, #b0e94f) no-repeat;
  background-size: 100% 100%;
  .rate {
    color: #fff;
  }
}
</style>
